import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import HTMLContent from '../components/Content'
import TVBHeader from '../components/TVBHeader'

import contactBackGround from '../../static/img/contact-background.jpg'


export const ContactPageTemplate = ({ title, content, contentComponent }) => {

  return (
    <div>
      <TVBHeader title="Contact" background={contactBackGround} isMedium={true}/>
      <section className="section tvb-contact">
        <div className="container">
        <div className="columns">
          <div className="column">
                <h4 className="title is-4 is-spaced">Contactgegevens</h4>
                <div className="content">
                  <span role="img" aria-label="telefoon">☎️</span><a className="link subtitle" href="tel:+32 475 407 665"> +32 475 407 665</a> <br/>
                  <span role="img" aria-label="mail">📬</span><a className="link subtitle" href="mailto:johan@vanbulckbvba.be"> johan@vanbulckbvba.be</a>
                </div> 

                <h4 className="title is-4 is-spaced">Adres</h4>
                <div className="content">
                  <div className="subtitle">Steenbeek 1 <br/>
                    2580 Putte - België</div>
                </div>               
            <h4 className="title is-4 is-spaced">Vragen?</h4>
            <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <input className="input-tvb" name="name" type="text" placeholder="Naam"/>
              <input className="input-tvb" name="email" type="email" placeholder="E-mailadres"/>
              <textarea className="input-tvb" name="message" rows="4" cols="50" placeholder="Bericht">
              </textarea>
              <button type="submit" className="btn-contact" 
              style={{display: 'block', width: '200px', textAlign: 'center', margin: 0, cursor: 'pointer', fontSize: '1rem'}}>Verzend</button>            
            </form>
          </div>
          <div className="column">
            <iframe title="Locatie" src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2507.046885565862!2d4.6432783158388515!3d51.07068455036027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3e2a0a4a92ed7%3A0x7cec994404897ab9!2sVan+Bulck+Tuinbouwbedrijf+bvba!5e0!3m2!1snl!2sbe!4v1536087399339'}
            width="600" frameBorder="0" style={{border:0, height: '100%', width: '100%'}} allowFullScreen={true}></iframe>
          </div>
        </div>
        </div>
      </section>
    </div>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Neem contact op met ons!" />
      </Helmet>
      <ContactPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
